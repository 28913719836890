



































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
// import {SelectOption} from "@/store/types";
import {FilterMixin} from "@/shared/mixins/FilterMixin";
// import {AddProductSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {mapGetters } from "vuex";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";

@Component({
  components: {
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker, SygniContainerTitle, SygniRoundedButton, SygniSquareButton, SygniSelect, SygniInput, SygniMultiSelect
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class TransactionsFilters extends mixins (FilterMixin) {
}
