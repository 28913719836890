var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transactions-module"
  }, [_c('transactions-blind-filters'), _c('div', {
    staticClass: "transactions-module__container"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Transactions")]), _c('transactions-blind-table', {
    attrs: {
      "show-page-number-options": true
    }
  })], 1)])])]), _c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }