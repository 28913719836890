

















import Vue from 'vue'
import Component from 'vue-class-component'
import TransactionsBlindFilters from '../components/TransactionsBlindFilters.vue';
import TransactionsBlindTable from '../components/TransactionsBlindTable.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniAccessModal from '@/components/layout/SygniAccessModal.vue';

@Component({
  components: { TransactionsBlindFilters, TransactionsBlindTable, SygniContainerTitle, SygniSelect, SygniAccessModal },
})
export default class TransactionsBlindModule extends Vue {
}
