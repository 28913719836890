
















































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BvTableField } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import breakpoints from '@/plugins/breakpoints';
import { mapGetters } from 'vuex';
import { Dictionaries } from '../../portfolio/store/types';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniSquareButton, SygniRectButton, SygniModal
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class TransactionsBlindTable extends SygniTable<any> {

  dictionaries!: Dictionaries;
  refresh: any = null;

  tableItems: any = [
    {
      code: "Transaction no. 1",
      date: "2021-01-28T00:00:00+00:00",
      registrationStatus: 'REGISTERED',
      registrationDate: "2021-01-28T00:00:00+00:00",
      createdAt: "2021-01-28T00:00:00+00:00",
      updatedAt: "2021-01-28T00:00:00+00:00",
    },
    {
      code: "Transaction no. 2",
      date: "2021-01-28T00:00:00+00:00",
      registrationStatus: 'REGISTERED',
      registrationDate: "2021-01-28T00:00:00+00:00",
      createdAt: "2021-01-28T00:00:00+00:00",
      updatedAt: "2021-01-28T00:00:00+00:00",
    },
    {
      code: "Transaction no. 3",
      date: "2021-01-28T00:00:00+00:00",
      registrationStatus: 'REGISTERED',
      registrationDate: "2021-01-28T00:00:00+00:00",
      createdAt: "2021-01-28T00:00:00+00:00",
      updatedAt: "2021-01-28T00:00:00+00:00",
    },
    {
      code: "Transaction no. 4",
      date: "2021-01-28T00:00:00+00:00",
      registrationStatus: 'REGISTERED',
      registrationDate: "2021-01-28T00:00:00+00:00",
      createdAt: "2021-01-28T00:00:00+00:00",
      updatedAt: "2021-01-28T00:00:00+00:00",
    },
  ];

  breakpoints = breakpoints;

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'code', sortable: true, class: 'table__head-cell company', label: 'Transaction code' },
    { key: 'date', sortable: true, class: 'table__head-cell', borderless: true, label: 'Transaction date' },
    { key: 'registrationStatus', sortable: true, class: 'table__head-cell', borderless: true, label: 'Registration status' },
    { key: 'registrationDate', sortable: true, class: 'table__head-cell', borderless: true, label: 'Registration date' },
    { key: 'createdAt', sortable: true, class: 'table__head-cell', borderless: true, label: 'Created' },
    { key: 'updatedAt', sortable: true, class: 'table__head-cell right', borderless: true, label: 'Updated' },
  ];

  async openDetails(rowData: any) {
    const item = (!rowData.item) ? rowData : rowData.item;

    this.$store.commit(this.setTableBusyMutation, true);
    item.details = await this.$store.dispatch('transactions/getTransactionById', {
      id: item.id,
      viewedContext: this.viewedId
    });

    this.$nextTick(() => {
      this.openDetailsModal(item.details, item.details.items);
      this.$store.commit(this.setTableBusyMutation, false);
    })
  }

  get filtersQuery() {
    return this.$store.getters['transactions/getTransactionsTableFiltersQuery'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  async beforeMount() {
    this.onMounted();
    this.setBorderlessTds();
  }
}

